import React from 'react';
import loadable from '@loadable/component';
import root from 'window-or-global';
import { string, object, bool } from 'prop-types';
import { navigate } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from 'components/cta/styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemIcon'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));
const Button = loadable(() => import('@material-ui/core/Button'));

const NavigateCta = ({
  icon, route,
  primary, secondary,
  primarySmall,
  externalRoute = false,
}) => {
  const styles = useStyles();

  const onNavigate = async () => {
    if (externalRoute) {
      root.open(route, '_blank');
    } else {
      const buildDataLayer = await import('data-layer');
      const dataLayer = buildDataLayer.default();
      dataLayer.trackNavigation({
        descriptor: 'navigate_cta',
        label: route,
      });
      navigate(route);
    }
  };

  return (
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
            <Hidden only={['xs']}>
              <Grid item>
                <ListItem
                  button
                  className={styles.listItem}
                  onClick={onNavigate}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={icon} size="2x" className={styles.icon}/>
                  </ListItemIcon>
                  <ListItemText
                    className={styles.listItemText}
                    primary={
                      <Typography variant="h6" component="p">
                       {primary}
                      </Typography>
                      }
                      secondary={secondary}
                  />
                </ListItem>
              </Grid>
            </Hidden>
            <Hidden only={['lg', 'md', 'sm', 'xl']}>
              <Button
                color="secondary"
                fullWidth
                variant="outlined"
                onClick={onNavigate}
                className={styles.xsButton}
                aria-label={`navigate to ${route}`}
                >
                <FontAwesomeIcon icon={icon} size="lg" className={styles.xsIcon}/>
                <Typography variant="h6" component="p" align="center" noWrap>
                 {primarySmall}
                </Typography>
              </Button>
              <Typography variant="caption" component="p" align="right" className={styles.xsMessage}>
               {secondary}
              </Typography>
            </Hidden>
          </Grid>
  );
};

NavigateCta.propTypes = {
  icon: object.isRequired,
  route: string.isRequired,
  primary: string.isRequired,
  primarySmall: string.isRequired,
  secondary: string.isRequired,
  externalRoute: bool,
};

export default NavigateCta;
